.css-bj2p8a-MuiPaper-root-MuiCard-root {
  overflow: visible !important;
}

.options_container {
  position: absolute;
  top: 60px;
  background-color: #1d1e20;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 6px;
  cursor: pointer;
  border-radius: 6px;
  width: 176.19px;
}

.search {
  margin: 8px 4px;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.option {
  margin: 0 4px;
  display: flex;
  gap: 12px;
  white-space: nowrap;
}
